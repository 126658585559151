import { render, staticRenderFns } from "./FlightsListItem.vue?vue&type=template&id=111ea085&scoped=true"
import script from "./FlightsListItem.vue?vue&type=script&lang=js"
export * from "./FlightsListItem.vue?vue&type=script&lang=js"
import style0 from "./FlightsListItem.vue?vue&type=style&index=0&id=111ea085&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111ea085",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlightsListItemLeg: require('/home/forge/farescraper.com/components/FlightsListItemLeg.vue').default,Dropdown: require('/home/forge/farescraper.com/components/Dropdown.vue').default,IconsPhone: require('/home/forge/farescraper.com/components/IconsPhone.vue').default})
